import users from "../../mock/users.json";
import tanks from "../../mock/tanks.json";
import measurements from "../../mock/measurements.json";
import sensors from "../../mock/sensors.json";
import companies from "../../mock/companies/companies.json";
import companiesWithShifts from "../../mock/companies/companiesWithShifts.json";
import shifts from "../../mock/shifts/shifts.json";
import shiftsChartMeasurements from "../../mock/shifts/shiftsChartMeasurements.json";
import shiftsPaginatedData from "../../mock/shifts/shiftsPaginatedData.json";
import alarmsSummaries from "../../mock/alarms/alarmsSummaries.json";

import jwt from "jsonwebtoken";

const resolvePathWithGuid = (path) =>
  new RegExp(path.replace(/:\w+/g, "[^/]+"));

export const mockUsers = (mock) => {
  const randomUser = users[Math.floor(Math.random() * users.length)];
  const randomUserRoute = resolvePathWithGuid(`/users/:id`);

  mock.onGet("/users").reply(200, users);
  mock.onGet(randomUserRoute).reply(200, randomUser);
  mock.onPost("/users/register").reply(200);
  mock.onPut(randomUserRoute).reply(200);
  mock.onDelete(randomUserRoute).reply(200);
};

export const mockLogin = (mock) => {
  const randomUser = users[Math.floor(Math.random() * users.length)];
  const token = jwt.sign(
    {
      ...randomUser,
    },
    "secret"
  );

  mock.onPost("/auth").reply(200, {
    ...randomUser,
    token,
  });
};

export const mockAlarms = (mock) => {
  const randomNumber = Math.floor(Math.random() * alarmsSummaries.length);

  mock.onGet("/alarms/summary").reply(200, alarmsSummaries[randomNumber]);
};

export const mockTanks = (mock) => {
  const randomTank = tanks[Math.floor(Math.random() * tanks.length)];

  const randomTankRoute = resolvePathWithGuid(`/tanks/:id$`);
  const randomMeasurementRoute = resolvePathWithGuid(`/tanks/:id/measurement$`);
  const randomMeasurementsRoute = resolvePathWithGuid(
    `/tanks/:id/measurements*`
  );
  const randomLastMeasurementsRoute = resolvePathWithGuid(
    `/tanks/:id/last/measurements`
  );
  const randomTankShiftsRoute = resolvePathWithGuid(
    `/tanks/shift-works/:id/last/history-measurements/`
  );

  mock.onGet("/tanks").reply(200, tanks);
  mock.onPost("/tanks").reply(200, tanks);
  mock.onGet("/tanks/measurements").reply(200, tanks);
  mock.onGet(randomTankRoute).reply(200, randomTank);
  mock.onPut(randomTankRoute).reply(200);
  mock.onDelete(randomTankRoute).reply(200);
  mock.onGet(randomLastMeasurementsRoute).reply(200, measurements);
  mock.onGet(randomMeasurementRoute).reply(200, randomTank);
  mock.onGet(randomMeasurementsRoute).reply(200, measurements);
  mock.onGet(randomTankShiftsRoute).reply(200, measurements);
};

export const mockSensors = (mock) => {
  const randomSensor = sensors[Math.floor(Math.random() * sensors.length)];
  const randomSensorRoute = resolvePathWithGuid(`/sensors/:id`);

  mock.onGet("/sensors").reply(200, sensors);
  mock.onPost("/sensors").reply(200);
  mock.onGet(randomSensorRoute).reply(200, randomSensor);
  mock.onPut(randomSensorRoute).reply(200);
  mock.onDelete(randomSensorRoute).reply(200);
};

export const mockCompanies = (mock) => {
  const randomCompany = companies[Math.floor(Math.random() * companies.length)];
  const randomCompanyRoute = resolvePathWithGuid(`/companies/:id$`);
  const randomCompanyShiftsRoute = resolvePathWithGuid(
    `/companies/:id/shiftWorks`
  );

  mock.onGet("/companies").reply(200, companies);
  mock.onGet("/companies/shiftworks").reply(200, companiesWithShifts);
  mock.onGet(randomCompanyRoute).reply(200, randomCompany);
  mock
    .onGet(randomCompanyShiftsRoute)
    .reply(
      200,
      companiesWithShifts[
        Math.floor(Math.random() * companiesWithShifts.length)
      ].shifts
    );
  mock.onPut(randomCompanyRoute).reply(200);
};

export const mockShifts = (mock) => {
  const randomLastShiftsRoute = resolvePathWithGuid(`/shifts/:id/last`);
  const randomChartRoute = new RegExp("/shiftworks/chart*");
  const tableRoute = new RegExp("/shiftworks/table*");

  mock.onGet(randomLastShiftsRoute).reply(200, shifts);
  mock.onGet(randomChartRoute).reply(200, shiftsChartMeasurements);
  mock.onGet(tableRoute).reply((req) => {
    let page = req.url.split("page=")[1];

    if (page.includes("&")) {
      page = page.split("&")[0];
    }

    return [200, shiftsPaginatedData[page - 1]];
  });
};
