import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import {
  mockUsers,
  mockLogin,
  mockAlarms,
  mockTanks,
  mockSensors,
  mockCompanies,
  mockShifts,
} from "./mock";

const axiosService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": localStorage.getItem("lang") || "pt-BR",
  },
});
if (process.env.VUE_APP_USE_MOCK_API === "true") {
  const mock = new MockAdapter(axiosService, { delayResponse: 1000 });

  mockUsers(mock);
  mockLogin(mock);
  mockAlarms(mock);
  mockTanks(mock);
  mockSensors(mock);
  mockCompanies(mock);
  mockShifts(mock);
}

export default axiosService;
