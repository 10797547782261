import Http from "./http";

const getUsers = () => Http.get("/users");

const getUser = (id) => Http.get(`/users/${id}`);

const deleteUser = (id) => Http.delete(`/users/${id}`);

const postUserRegister = (user) => Http.post("/users/register", user);

const putUser = (id, user) => Http.put(`/users/${id}`, user);

export default {
  getUsers,
  getUser,
  deleteUser,
  postUserRegister,
  putUser,
};
