<template>
  <div>
    <v-app-bar
      :color="background"
      height="auto"
      class="mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
      :class="
        navbarFixed
          ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
          : ''
      "
      flat
    >
      <v-row class="py-1">
        <v-col class="d-flex align-center">
          <div
            class="drawer-toggler py-5 mr-6 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-body"></i>
              <i class="drawer-toggler-line bg-body"></i>
              <i class="drawer-toggler-line bg-body"></i>
            </div>
          </div>
          <h6 class="text-h4 font-weight-bolder text-typo mb-0">
            {{ $t($route.name) }}
          </h6>
        </v-col>

        <v-col
          class="d-flex align-center"
          :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
        >
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="300"
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
                class="text-body ms-auto"
                :color="linkColor"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon class="material-icons-round" size="20">
                  notifications
                </v-icon>
                <v-badge
                  color="#f44335"
                  :content="numberNotifications"
                  offset-x="1"
                  offset-y="0"
                  class="position-absolute"
                  bordered
                  v-if="numberNotifications > 0"
                >
                </v-badge>
              </v-btn>
            </template>

            <v-list class="pa-3" v-if="numberNotifications > 0">
              <v-list-item
                v-for="(item, i) in sortedAlarms"
                :key="i"
                class="
                  pa-4
                  list-item-hover-active
                  d-flex
                  align-center
                  py-1
                  my-1
                  border-radius-md
                "
              >
                <v-list-item-icon class="ma-auto">
                  <v-icon
                    class="material-icons-round"
                    size="20"
                    :color="color(item.alarmMeasurementLevel)"
                  >
                    {{ icon(item.alarmMeasurementLevel) }}
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  >
                    <v-row>
                      <v-col>
                        <h6 class="text-sm font-weight-normal ms-2 text-typo">
                          {{ item.tanque }}:
                          {{
                            $t(
                              `alarmsAndWarnings.levels.${item.alarmMeasurementLevel}`
                            )
                          }}
                        </h6>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list class="pa-3 d-flex aling-center, justify-center" v-else>
              <p class="mb-0">{{ $t("alarmsAndWarnings.noAlarmsFound") }}</p>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <drawer-mobile :drawerMobile="drawerMobile" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import alarmLevels from "@/data/constants/alarmLevels";
import DrawerMobile from "./DrawerMobile.vue";

export default {
    name: "app-bar",
    props: {
        background: String,
        hasBg: Boolean,
        linkColor: String,
        toggleActive: Boolean,
        navbarFixed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
          drawerMobile: true,
          drawer: false,
          togglerActive: false,
          numberNotifications: 0,
        };
    },
    methods: {
        ...mapActions(["setError", "setAlarmsSummary"]),
        async getAlarmsAndWarningsSummary() {
            try {
                const response = await this.$api.alarm.getAlarmsSummary();
                this.setAlarmsSummary(response.data);
                this.numberNotifications =
                    response.data.alarms + response.data.warnings;
            }
            catch (error) {
                this.setError(error);
            }
        },
        drawerClose() {
            this.togglerActive = !this.togglerActive;
            this.$emit("drawer-toggle", true);
        },
        minifyDrawer() {
            this.togglerActive = !this.togglerActive;
            this.mini = !this.mini;
            this.isVisibleDrawer()
            const body = document.getElementsByTagName("body")[0];

            body.classList.toggle("drawer-mini")
        },
        isVisibleDrawer() {
          this.drawerMobile = !this.drawerMobile
        },
        icon(alarmMeasurementLevel) {
            if (alarmMeasurementLevel === alarmLevels.CRITICAL) {
                return "report";
            }
            if (alarmMeasurementLevel === alarmLevels.LOW) {
                return "warning";
            }
            if (alarmMeasurementLevel === alarmLevels.LOWLOW) {
                return "error";
            }
            return "exclamation";
        },
        color(alarmMeasurementLevel) {
            if (alarmMeasurementLevel === alarmLevels.CRITICAL) {
                return "#ff0000";
            }
            if (alarmMeasurementLevel === alarmLevels.LOW) {
                return "#f47c22";
            }
            if (alarmMeasurementLevel === alarmLevels.LOWLOW) {
                return "#00b6ff";
            }
            return "#000";
        },
    },
    watch: {
        toggleActive(val) {
          this.togglerActive = val;
        },
    },
    mounted() {
        this.getAlarmsAndWarningsSummary();
    },
    computed: {
        ...mapGetters(["alarmsSummary"]),
        sortedAlarms() {
            const { messages } = this.alarmsSummary;
            return messages.sort((a, b) => alarmLevels.order.indexOf(a.alarmMeasurementLevel) -
                alarmLevels.order.indexOf(b.alarmMeasurementLevel));
        },
    },
    components: { DrawerMobile }
};
</script>
