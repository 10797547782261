// Pathify
import { make } from "vuex-pathify";

const state = {
  loading: false,
  alertNotification: {
    show: false,
    color: "info",
    data: {},
  },
};

const mutations = make.mutations(state);

const actions = {
  notificationError({ commit }, data) {
    commit("alertNotification", {
      show: true,
      color: "error",
      data: data,
    });
  },
  notificationSuccess({ commit }, data) {
    commit("alertNotification", {
      show: true,
      color: "success",
      data: data,
    });
  },
  showLoading({ commit }) {
    commit("loading", true);
  },
  hideLoading({ commit }) {
    commit("loading", false);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

// Adicionar açãoe de erro e loading corretos
//   .catch((error) => {
//     this.setError(error);
//   });
