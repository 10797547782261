<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    color="#051c23"
  >
    <div
      class="
        v-navigation-drawer-brand
        pa-5
        d-flex
        align-center
        justify-content-center
      "
    >
      <v-img
        src="@/assets/img/logo-white.png"
        class="navbar-brand-img"
        width="190"
      >
      </v-img>
    </div>

    <hr class="horizontal mb-0 dark" />

    <hr class="horizontal mb-3 dark" />

    <v-list nav dense class="text-white" style="height: 80%">
      <v-list-item-group class="d-flex flex-column h-100 justify-space-between">
        <div>
          <div v-for="(item, i) in items" :key="i">
            <v-list-item
              link
              :to="item.link"
              class="pb-1 mx-2 no-default-hover py-2"
              :ripple="false"
              active-class="item-active"
            >
              <v-list-item-icon class="me-2 align-center text-white">
                <i class="material-icons-round opacity-10">{{ item.action }}</i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1 text-white">{{
                  $t(item.title)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list-group
            :ripple="false"
            v-for="item in subItems"
            :key="item.title"
            v-model="item.active"
            append-icon="fas fa-angle-down"
            class="pb-1 mx-2 no-default-hover"
          >
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center text-white py-5">
                <i class="material-icons-round opacity-10">{{ item.action }}</i>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="ms-1 text-white">{{
                  $t(item.title)
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :ripple="false"
              link
              class="mb-1 no-default-hover px-0 text-white has-children"
              v-for="child in item.items"
              :key="child.title"
              :to="child.link"
              active-class="item-active"
            >
              <v-list-item-content class="ps-4">
                <div class="d-flex align-items-center pa-2 text-white">
                  <i class="material-icons-round opacity-10">{{
                    child.action
                  }}</i>
                  <v-list-item-title class="ms-6">{{
                    $t(child.title)
                  }}</v-list-item-title>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>

        <div>
          <v-list-item
            link
            :to="'/login'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center text-white">
              <i class="material-icons-round opacity-10">logout</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="ms-1 text-white">{{
                $t("logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  data: () => ({
    mini: false,
    items: [
      {
        action: "water_drop",
        link: "/tank",
        title: "tanks",
      },
      {
        action: "description",
        link: "/report",
        title: "reports",
      },
      {
        action: "person",
        link: "/user",
        title: "users",
      },
    ],
    subItems: [
      {
        action: "settings",
        active: false,
        title: "settings",
        items: [
          {
            title: "sensors",
            action: "sensors",
            link: "/sensors",
          },
          {
            title: "shifts",
            action: "more_time",
            link: "/shifts",
          },
        ],
      },
    ],
  }),
};
</script>
