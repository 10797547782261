import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import routeHistory from "@/store/modules/routeHistory";
import messages from "@/store/modules/messages";
import shiftsTable from "@/store/modules/shiftsTable";
import alarmsModal from "@/store/modules/alarmsModal";
import interceptor from "@/store/modules/interceptor";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    routeHistory,
    messages,
    shiftsTable,
    alarmsModal,
    interceptor,
  },
});
