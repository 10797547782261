export default {
  state: {
    token: null,
    user: null,
    isAuthenticated: false,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
  },
  actions: {
    authenticateUser(
      { commit },
      { token, id, firstName, lastName, username, role }
    ) {
      commit("setIsAuthenticated", true);
      commit("setToken", token);
      commit("setUser", {
        id,
        firstName,
        lastName,
        username,
        role,
      });

      localStorage.setItem("token", token);
    },
    logoutUser({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      commit("setIsAuthenticated", false);
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    user(state) {
      return {
        ...state.user,
        fullName: `${state.user.firstName} ${state.user.lastName}`,
      };
    },
    token(state) {
      return state.token;
    },
    isAdmin(state) {
      return state.user.role === "Admin";
    },
  },
};
