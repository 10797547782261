import alarm from "./api.alarm";
import auth from "./api.auth";
import company from "./api.company";
import sensor from "./api.sensor";
import shiftWork from "./api.shift-work";
import tank from "./api.tank";
import user from "./api.user";

export default {
  alarm,
  auth,
  company,
  sensor,
  shiftWork,
  tank,
  user,
};
