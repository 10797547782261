import Http from "./http";

const getTanks = () => Http.get("/tanks");

const getTank = (id) => Http.get(`/tanks/${id}`);

const getTanksMeasurements = () => Http.get("/tanks/measurements");

const getTankMeasurements = (id) => Http.get(`/tanks/${id}/measurement`);

const getTankMeasurementsOverPeriod = (id, initialDateTime, finalDateTime) =>
  Http.get(
    `/tanks/${id}/measurements?dateStart=${initialDateTime}&dateEnd=${finalDateTime}`
  );

const getTankLastMeasurements = (id) =>
  Http.get(`/tanks/${id}/last/measurements`);

const getTankLastEndsOfShiftsMeasurements = (id) =>
  Http.get(`/tanks/shift-works/${id}/last/history-measurements/`);

const deleteTank = (id) => Http.delete(`/tanks/${id}`);

const postTank = (requestBody) => Http.post("/tanks", requestBody);

const putTank = (id, requestBody) => Http.put(`/tanks/${id}`, requestBody);

export default {
  getTanks,
  getTank,
  getTanksMeasurements,
  getTankMeasurements,
  getTankMeasurementsOverPeriod,
  getTankLastMeasurements,
  getTankLastEndsOfShiftsMeasurements,
  deleteTank,
  postTank,
  putTank,
};
