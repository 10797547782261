export default {
  state: () => ({
    showAlarmsModal: false,
    alarmsSummary: {},
  }),
  mutations: {
    setAlarmsModal(state, showAlarmsModal) {
      state.showAlarmsModal = showAlarmsModal;
    },
    setAlarmsSummary(state, alarmsSummary) {
      state.alarmsSummary = alarmsSummary;
    },
  },
  actions: {
    showAlarmsModal({ commit }) {
      commit("setAlarmsModal", true);
    },
    hideAlarmsModal({ commit }) {
      commit("setAlarmsModal", false);
    },
    setAlarmsSummary({ commit }, alarmsSummary) {
      commit("setAlarmsSummary", alarmsSummary);
    },
  },
  getters: {
    showAlarmsModal: (state) => state.showAlarmsModal,
    alarmsSummary: (state) => state.alarmsSummary,
  },
};
