import Http from "./http";

const deleteSensor = (id) => Http.delete(`/sensors/${id}`);

const getSensors = () => Http.get("/sensors");

const getSensor = (id) => Http.get(`/sensors/${id}`);

const postSensor = (requestBody) => Http.post("/sensors", requestBody);

const putSensor = (id, requestBody) => Http.put(`/sensors/${id}`, requestBody);

export default {
  deleteSensor,
  getSensors,
  getSensor,
  postSensor,
  putSensor,
};
