<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="position-relative min-vh-100"
        :style="`background-color:  #072936`"
      >
        <v-container class="my-auto">
          <v-row class="min-vh-100">
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <v-container class="pb-0 position-relative">
                <router-view></router-view>
              </v-container>
            </fade-transition>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  name: "page-layout",
  components: {
    FadeTransition,
  },
};
</script>
