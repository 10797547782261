import Http from "../api/http";
import router from "../router";

export function setupInterceptors() {
  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending++;
      // dispatch("interceptor/showLoading");
    },
    done: () => {
      requestsPending--;
      if (requestsPending <= 0) {
        // dispatch("interceptor/hideLoading");
      }
    },
  };

  Http.interceptors.request.use(
    async (config) => {
      req.pending();
      const token = localStorage.getItem("token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      requestsPending -= 1;
      req.done();

      return Promise.reject(error);
    }
  );

  Http.interceptors.response.use(
    (response) => {
      req.done();
      return Promise.resolve(response);
    },
    (error) => {
      req.done();
      if (error.response && error.response.status === 401) {
        router.push("/login");
      }

      return Promise.reject(error);
    }
  );
}
