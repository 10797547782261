import Http from "./http";

const getWorkShiftsFromCompany = (companyId) =>
  Http.get(`/companies/${companyId}/shiftWorks`);

const getInstallations = () => Http.get("/companies/shiftworks");

const shiftWorksChart = (
  initialDateTime,
  finalDateTime,
  tankId,
  companyId,
  shiftId
) =>
  Http.get(
    `/shiftworks/chart?initialDateTime=${initialDateTime}&finalDateTime=${finalDateTime}&tankId=${tankId}&companyId=${companyId}&shiftId=${shiftId}`
  );

const shiftWorksTable = (
  initialDateTime,
  finalDateTime,
  tankId,
  companyId,
  shiftId
) =>
  Http.get(
    `/shiftworks/table?initialDateTime=${initialDateTime}&finalDateTime=${finalDateTime}&tankId=${tankId}&companyId=${companyId}&shiftId=${shiftId}`
  );

const putShifts = (installationId, installation) =>
  Http.put(`/companies/${installationId}/shiftworks`, installation);

export default {
  getWorkShiftsFromCompany,
  getInstallations,
  shiftWorksChart,
  shiftWorksTable,
  putShifts,
};
