export default {
  state: () => ({
    message: null,
  }),
  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
  actions: {
    setError({ commit }, error) {
      commit("setMessage", {
        text: error?.response?.data?.message || error,
        show: true,
        type: "error",
      });
    },
    setWarning({ commit }, message) {
      commit("setMessage", {
        text: message,
        show: true,
        type: "warning",
      });
    },
    setSuccess({ commit }, message) {
      commit("setMessage", {
        text: message,
        show: true,
        type: "success",
      });
    },
    clearMessage({ commit }) {
      commit("clearMessage");
    },
  },
  getters: {
    message: (state) => state.message,
  },
};
