import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import "./plugins/vee-validate";
import "@/plugins/echarts";
import api from "./api";
import { sync } from "vuex-router-sync";
import { setupInterceptors } from "./plugins/axios-interceptor";

Vue.config.productionTip = false;
Vue.prototype.$api = api;

sync(store, router);

Vue.use(DashboardPlugin);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  created() {
    setupInterceptors(store);
  },
  render: (h) => h(App),
}).$mount("#app");
