import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import jwt from "jsonwebtoken";

// Pages
const Report = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Report/Report.vue");
const DataReport = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Report/DataHistoryReport.vue"
  );
const ShiftsEndsReport = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Report/ShiftsReport.vue");
const Tank = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Tank/Tank.vue");
const TankEdit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Tank/TankEdit.vue");
const TankInfo = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Tank/TankInfo.vue");
const Sensor = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Sensor/Sensors.vue");
const SensorEdit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Sensor/SensorEdit.vue");
const Shift = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Shift/Shifts.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Authentication/Login.vue");
const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/User/User.vue");
const UserEdit = () =>
  import(/* webpackChunkName: "pages" */ "@/views/User/UserEdit.vue");

Vue.use(VueRouter);

let pages = {
  path: "/",
  redirect: "/tank",
  component: DashboardLayout,
  name: "Pages",
  children: [
    {
      path: "/tank",
      name: "tank",
      component: Tank,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/tanks/new",
      name: "newTank",
      component: TankEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "tank",
      },
    },
    {
      path: "/tankedit/:id",
      name: "EditTank",
      component: TankEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "tankData.title",
      },
    },
    {
      path: "/tankinfo/:id",
      name: "tankInfo",
      component: TankInfo,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "tankData.title",
      },
    },
    {
      path: "/report",
      name: "reports",
      component: Report,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/report/data-history/:id/:initialDateTime/:finalDateTime",
      name: "dataHistoryReport",
      component: DataReport,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/report/shifts/:initialDateTime/:finalDateTime/:tankId?/:companyId?/:shiftId?",
      name: "shiftsEndsReport",
      component: ShiftsEndsReport,
      meta: {
        requiresAuth: true,
        title: "reports",
      },
    },
    {
      path: "/user",
      name: "users",
      component: User,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/user/new",
      name: "newUser",
      component: UserEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "user",
      },
    },
    {
      path: "/useredit/:id",
      name: "editUser",
      component: UserEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "userData.firstName",
      },
    },
    {
      path: "/sensors",
      name: "sensors",
      component: Sensor,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
    {
      path: "/sensors/new",
      name: "newSensor",
      component: SensorEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "sensor",
      },
    },
    {
      path: "/sensoredit/:id",
      name: "editSensor",
      component: SensorEdit,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
        title: "sensorData.title",
      },
    },
    {
      path: "/shifts",
      name: "shifts",
      component: Shift,
      meta: {
        requiresAuth: true,
        groupName: "Pages",
      },
    },
  ],
};

let authBasicPages = {
  path: "/",
  component: AuthBasicLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
  ],
};

const routes = [pages, authBasicPages];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const LOGIN_PATH = "/login";

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.path == LOGIN_PATH) {
    localStorage.removeItem("token");
    next();
    return;
  }

  if (token) {
    const decoded = jwt.decode(token);
    var currentTime = new Date().getTime() / 1000;

    if (currentTime > decoded.exp) {
      localStorage.removeItem("token");
      next(LOGIN_PATH);
    }

    const { firstName, lastName, username, role } =
      router.app.$store.state.auth.user ?? decoded;

    router.app.$store.dispatch("authenticateUser", {
      id: decoded.id,
      firstName,
      lastName,
      username,
      role,
      token,
    });
  }

  if (to.meta.requiresAuth && !router.app.$store.state.auth.isAuthenticated) {
    router.app.$store.dispatch("clearHistory");
    next(LOGIN_PATH);
    return;
  }

  if (
    router.app.$store.getters.history.length === 0 &&
    sessionStorage.getItem("routeHistory")
  ) {
    const historyState = JSON.parse(sessionStorage.getItem("routeHistory"));

    router.app.$store.dispatch("setHistory", historyState.history);
    router.app.$store.dispatch("setCurrent", historyState.currentIndex);
  }

  next();
});

router.afterEach((to) => {
  const existentRoute = router.app.$store.getters.history.find(
    (x) => x.path === to.path
  );

  if (!existentRoute) {
    router.app.$store.dispatch("addRoute", to.path);
    return;
  }

  if (
    existentRoute.index !== router.app.$store.state.routeHistory.currentIndex
  ) {
    router.app.$store.dispatch("setCurrent", existentRoute.index);
  }
});

export default router;
