<template>
  <v-app>
    <v-theme-provider root>
      <router-view />
    </v-theme-provider>
    <toaster />
  </v-app>
</template>

<script>
import Toaster from "@/components/Toaster.vue";
export default {
  name: "App",
  components: {
    Toaster,
  },
};
</script>
