export default {
  state: {
    history: [],
    currentIndex: null,
  },
  mutations: {
    add(state, route) {
      state.history.push(route);
    },
    setCurrent(state, route) {
      state.currentIndex = route;
    },
    setHistory(state, history) {
      state.history = history;
    },
    clearHistory(state) {
      state.history = [];
      state.currentIndex = null;
    },
  },
  actions: {
    addRoute({ state, commit }, route) {
      const currentRoute = state.history.find(
        (x) => x.index === state.currentIndex
      );

      if (currentRoute && route === currentRoute.path) {
        return;
      }

      const newRoute = {
        path: route,
        index: (state.currentIndex = state.currentIndex + 1 || 0),
      };

      if (state.history.find((x) => x.index === state.currentIndex + 1)) {
        commit(
          "setHistory",
          state.history.filter((x) => x.index < state.currentIndex)
        );
      }

      commit("add", newRoute);
      commit("setCurrent", newRoute.index);

      sessionStorage.setItem("routeHistory", JSON.stringify(state));
    },
    goToPrevious({ state, commit }) {
      if (state.history[state.currentIndex - 1]) {
        commit("setCurrent", state.currentIndex - 1);
      }

      sessionStorage.setItem("routeHistory", JSON.stringify(state));
    },
    goToNext({ state, commit }) {
      if (state.history[state.currentIndex + 1]) {
        commit("setCurrent", state.currentIndex + 1);
      }

      sessionStorage.setItem("routeHistory", JSON.stringify(state));
    },
    setHistory({ commit }, history) {
      commit("setHistory", history);
    },
    setCurrent({ commit }, index) {
      commit("setCurrent", index);
    },
    clearHistory({ commit }) {
      sessionStorage.removeItem("routeHistory");
      commit("clearHistory");
    },
  },
  getters: {
    history: (state) => state.history,
    currentRoute: (state) =>
      state.history.find((x) => x.index === state.currentIndex),
    previousRoute: (state) =>
      state.history.find((x) => x.index === state.currentIndex - 1),
    nextRoute: (state) =>
      state.history.find((x) => x.index === state.currentIndex + 1),
  },
};
