import Vue from "vue";
import VueI18n from "vue-i18n";
import dateTimeFormats from "./dateTimeFormats";
import pt from "./languages/pt-BR.json";
import en from "./languages/en-US.json";
import es from "./languages/es-ES.json";
import { configure } from "vee-validate";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "pt-BR",
  fallbackLocale: "pt-BR",
  dateTimeFormats,
  messages: {
    "pt-BR": pt,
    "en-US": en,
    "es-ES": es,
  },
});
configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

export default i18n;
