import {
  itemsPerPageOptions,
  defaultItemsPerPage,
} from "@/data/constants/pagination";

export default {
  state: {
    page: 1,
    pageSize:
      itemsPerPageOptions.find((option) => option === defaultItemsPerPage) ||
      itemsPerPageOptions[0],
    pagesTotal: 0,
    registersTotal: 0,
    data: [],
    loadingDataTable: false,
  },
  mutations: {
    setPage(state, page) {
      state.page = page;
    },
    setPageSize(state, pageSize) {
      state.pageSize = pageSize;
    },
    setPagesTotal(state, pagesTotal) {
      state.pagesTotal = pagesTotal;
    },
    setRegistersTotal(state, registersTotal) {
      state.registersTotal = registersTotal;
    },
    setData(state, data) {
      state.data = data;
    },
    setLoadingDataTable(state, loadingDataTable) {
      state.loadingDataTable = loadingDataTable;
    },
  },
  actions: {
    setPage({ commit }, page) {
      commit("setPage", page);
    },
    setPageSize({ commit }, pageSize) {
      commit("setPageSize", pageSize);
    },
    setPagesTotal({ commit }, pagesTotal) {
      commit("setPagesTotal", pagesTotal);
    },
    setRegistersTotal({ commit }, registersTotal) {
      commit("setRegistersTotal", registersTotal);
    },
    setData({ commit }, data) {
      commit("setData", data);
    },
    setLoadingDataTable({ commit }, loadingDataTable) {
      commit("setLoadingDataTable", loadingDataTable);
    },
  },
  getters: {
    page: (state) => state.page,
    pageSize: (state) => state.pageSize,
    pagesTotal: (state) => state.pagesTotal,
    registersTotal: (state) => state.registersTotal,
    data: (state) => state.data,
    loadingDataTable: (state) => state.loadingDataTable,
  },
};
